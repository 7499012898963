import React, { useEffect, useState } from "react";
import { Column } from "@ant-design/plots";
import axios from "axios";
import { Tooltip, Col, Row, Button, Spin, Empty, DatePicker } from "antd";
import { CSVLink } from "react-csv";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import moment from "moment";

const weekFormat = "YYYY-MM-DD";
const customWeekStartEndFormat = (value) =>
  `${moment(value).startOf("week").format(weekFormat)} To ${moment(value)
    .endOf("week")
    .format(weekFormat)}`;

const defaultStartWeek = moment(new Date()).startOf("week").format(weekFormat);
const defaultEndWeek = moment(new Date()).endOf("week").format(weekFormat);
var initialWeek = defaultStartWeek + " To " + defaultEndWeek;

export default function OpportunityBarWeekly() {
  const [barData, setBarData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [week, setWeek] = useState(initialWeek);

  useEffect(() => {
    getAllMonthData();
  }, [week]);

  const params = {
    filter_type: "WEEKLY",
    start_date: week.slice(0, 10),
    end_date: week.slice(14, 24),
  };

  const getAllMonthData = () => {
    const url =
      "https://bgzz8fj81j.execute-api.ap-south-1.amazonaws.com/dev/dashboard/quarterly-weekly-data";
    axios
      .get(url, { params: params })
      .then((res) => {
        setBarData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setBarData(error.response.data);
          setLoading(false);
        }
      });
  };

  // function compare(a, b) {
  //   if (a.opp_count < b.opp_count) {
  //     return -1;
  //   }
  //   if (a.opp_count > b.opp_count) {
  //     return 1;
  //   }
  //   return 0;
  // }

  const config = {
    data: barData,
    xField: "dated",
    yField: "opp_count",
    seriesField: "opportunity_type_name",
    isGroup: true,
    columnStyle: {
      radius: [0, 0, 0, 0],
    },
    minColumnWidth: 20,
    maxColumnWidth: 40,
    label: {
      content: "",
      position: "middle",
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
    },
  };

  const headers = [
    { label: "Month", key: "month" },
    { label: "Value", key: "value" },
  ];
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 34,
      }}
      spin
    />
  );

  const onChange = async (date, dateString) => {
    setWeek(dateString);
    console.log("WeekChange==========>", dateString);
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  return (
    <div>
      <div className="d-flex justify-content-center mb-2">
        <h5 className="text-secondary " style={{ fontSize: "1rem" }}>
          Number of Opportunities
        </h5>
        <h6
          className="text-secondary mt-1 ms-3"
          style={{ fontSize: "0.75rem" }}
        >
          Weekly
        </h6>
      </div>
      <Row gutter={[8, 8]} className="my-3">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <DatePicker
            allowClear={false}
            disabledDate={disabledDate}
            defaultValue={moment()}
            format={customWeekStartEndFormat}
            picker="week"
            onChange={onChange}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <CSVLink
            className="float-end btn-dwn"
            data={barData}
            headers={headers}
            filename={"Total_Opportunities_Monthly"}
          >
            <Tooltip title="Export to csv">
              <div className="download-btn">
                <Button size="large" type="text">
                  <DownloadOutlined className="text-black " />
                </Button>
              </div>
            </Tooltip>
          </CSVLink>
        </Col>
      </Row>

      {loading && (
        <div className="mt-5 d-grid">
          <Spin indicator={antIcon} />
          <p className="d-flex justify-content-center text-secondary">
            Loading...
          </p>
        </div>
      )}
      {!barData.length && !loading ? (
        <Empty
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 100,
          }}
        />
      ) : (
        <Column {...config} />
      )}
    </div>
  );
}
