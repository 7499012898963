import React, { useEffect, useState } from "react";
import { Column } from "@ant-design/plots";
import axios from "axios";
import { Form, Tooltip, Col, Row, Button, Spin, Empty, DatePicker } from "antd";
import { CSVLink } from "react-csv";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import moment from "moment";

const monthFormat = "YYYY";
const defaultYear = moment(new Date()).year();
var initalMonth = defaultYear;

export default function OpportunityBarQuarterly() {
  const [barData, setBarData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  useEffect(() => {
    getAllMonthData();
  }, [selectedYear]);

  const params = { filter_type: "QUARTERLY", year: selectedYear };

  const getAllMonthData = () => {
    const url =
      "https://bgzz8fj81j.execute-api.ap-south-1.amazonaws.com/dev/dashboard/quarterly-weekly-data";
    axios
      .get(url, { params: params })
      .then((res) => {
        setBarData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setBarData(error.response.data);
          setLoading(false);
        }
      });
  };

  const config = {
    data: barData,
    xField: "quarter_data",
    yField: "opp_count",
    seriesField: "opportunity_type_name",
    isGroup: true,
    columnStyle: {
      radius: [0, 0, 0, 0],
    },
    minColumnWidth: 20,
    maxColumnWidth: 40,
    label: {
      content: "",
      position: "middle",
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
    },
  };

  const headers = [
    { label: "Month", key: "month" },
    { label: "Value", key: "value" },
  ];
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 34,
      }}
      spin
    />
  );

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const handleMonthChange = async (data, dateString) => {
    setLoading(true);
    setBarData([]);
    setSelectedYear(dateString.slice(0, 4));
    setSelectedMonth(dateString.slice(5, 8));
  };
  return (
    <div>
      <div className="d-flex justify-content-center mb-2">
        <h5 className="text-secondary " style={{ fontSize: "1rem" }}>
          Number of Opportunities
        </h5>
        <h6
          className="text-secondary mt-1 ms-3"
          style={{ fontSize: "0.75rem" }}
        >
          Quarterly
        </h6>
      </div>
      <Row gutter={[8, 8]} className="my-3">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <DatePicker
            allowClear={false}
            disabledDate={disabledDate}
            onChange={handleMonthChange}
            defaultValue={moment(initalMonth, monthFormat)}
            format={monthFormat}
            picker="year"
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <CSVLink
            className="float-end btn-dwn"
            data={barData}
            headers={headers}
            filename={"Total_Opportunities_Monthly"}
          >
            <Tooltip title="Export to csv">
              <div className="download-btn">
                <Button size="large" type="text">
                  <DownloadOutlined className="text-black " />
                </Button>
              </div>
            </Tooltip>
          </CSVLink>
        </Col>
      </Row>

      {loading && (
        <div className="mt-5 d-grid">
          <Spin indicator={antIcon} />
          <p className="d-flex justify-content-center text-secondary">
            Loading...
          </p>
        </div>
      )}
      {!barData.length && !loading ? (
        <Empty
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 100,
          }}
        />
      ) : (
        <Column {...config} />
      )}
    </div>
  );
}
