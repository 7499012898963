import React, { useState, useEffect } from "react";
import { Pie } from "@ant-design/plots";
import {
  Button,
  Col,
  DatePicker,
  Row,
  Tooltip,
  Typography,
  Spin,
  Empty,
} from "antd";
import { CSVLink } from "react-csv";
import moment from "moment";
import axios from "axios";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";

const monthFormat = "YYYY-MM";
const defaultMonth = moment(new Date()).month() + 1;
const defaultYear = moment(new Date()).year();
// let checkStartOfMonth = moment().format("D");
var initalMonth = defaultYear + "-" + defaultMonth;
// if (checkStartOfMonth == 1) {
//   initalMonth = moment(new Date()).month() + "-" + moment(new Date()).year();
// } else {
//   if (defaultMonth < 10) initalMonth = defaultYear + "-0" + defaultMonth;
//   else initalMonth = defaultYear + "0" + defaultMonth;
// }

export default function OpportunityStatus() {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [loading, setLoading] = useState(true);
  const [pieData, setPieData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const params = {
    month: selectedMonth,
    year: selectedYear,
    graph_type: "opp_status",
  };
  useEffect(() => {
    getAlllOpportunityTypeData();
  }, [selectedMonth, selectedYear]);

  const getAlllOpportunityTypeData = () => {
    const url =
      "https://bgzz8fj81j.execute-api.ap-south-1.amazonaws.com/dev/dashboard/opp-piechart-data";
    axios
      .get(url, { params: params })
      .then((res) => {
        setPieData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setPieData(error.response.data);
          setLoading(false);
        }
      });
  };
  const handleMonthChange = async (data, dateString) => {
    setLoading(true);
    setPieData([]);
    setSelectedYear(dateString.slice(0, 4));
    setSelectedMonth(dateString.slice(5, 8));
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const config = {
    appendPadding: 10,
    data: pieData,
    angleField: "opp_count",
    colorField: "status",
    radius: 0.8,
    label: {
      type: "spider",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };
  const headers = [
    { label: "Status", key: "status" },
    { label: "Opportunity Count", key: "opp_count" },
    { label: "Month", key: "month" },
    { label: "Year", key: "year" },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 34,
      }}
      spin
    />
  );
  return (
    <div>
      <div className="d-flex justify-content-center mb-2">
        <h5 className="text-secondary " style={{ fontSize: "1rem" }}>
          Opportunities By Status
        </h5>

        <h6
          className="text-secondary mt-1 ms-3"
          style={{ fontSize: "0.75rem" }}
        >
          Monthly
        </h6>
      </div>
      <Row gutter={[8, 8]} className="my-3">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <DatePicker
            allowClear={false}
            disabledDate={disabledDate}
            onChange={handleMonthChange}
            defaultValue={moment(initalMonth, monthFormat)}
            format={monthFormat}
            picker="month"
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <CSVLink
            className="float-end btn-dwn"
            data={pieData}
            headers={headers}
            filename={"Opportunities_Status_Monthly"}
          >
            <Tooltip title="Export to csv">
              <div className="download-btn">
                <Button size="large" type="text">
                  <DownloadOutlined className="text-black " />
                </Button>
              </div>
            </Tooltip>
          </CSVLink>
        </Col>
      </Row>
      {loading && (
        <div className="mt-5 d-grid">
          <Spin indicator={antIcon} />
          <p className="d-flex justify-content-center text-secondary">
            Loading...
          </p>
        </div>
      )}
      {!pieData.length && !loading ? (
        <Empty
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 100,
          }}
        />
      ) : (
        <Pie {...config} />
      )}
    </div>
  );
}
