import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Breadcrumb from "../BreadCrumbs";
import {
  Row,
  Col,
  Descriptions,
  Typography,
  Divider,
  Button,
  Tooltip,
} from "antd";
import { routes } from "../../util/constants";
import { AuditOutlined } from "@ant-design/icons";
import UpdateOpportunity from "../../redux/opportunity/UpdateOpportunity";

export default function OpportunitiesDetails(props) {
  const navigate = useNavigate();
  useEffect(() => {
    console.log("propss", props);
  }, []);

  const navigateToAuditsPage = (data) => {
    console.log("dataaaa", data);
    navigate(routes.OPPORTUNITY_AUDITS, { state: data });
  };
  const DetailItem = ({ title, detail }) => {
    return (
      <div>
        <Col>
          <Typography.Text
            style={{
              fontSize: "13px",
              color: "grey",
            }}
          >
            {title}
          </Typography.Text>
          <Typography.Title
            style={{
              color: "rgb(105 105 105)",
              margin: 0,
            }}
            level={5}
          >
            {detail}
          </Typography.Title>
        </Col>
      </div>
    );
  };

  return (
    <>
      <div className="container dashboard statistics-card">
        <Row className="title-row">
          <Col xs={20} sm={20} md={20} lg={20} xl={20}>
            <div className="pages-header">Opportunity Details</div>
            <Breadcrumb location={props.location} />
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
            <Tooltip title="Audits">
              <Button
                // icon={<AuditOutlined />}
                className="float-end"
                onClick={(event) => navigateToAuditsPage(props.location.state)}
                style={{
                  color: "white",
                  background: "#f45759",
                }}
              >
                Audit
              </Button>
            </Tooltip>
          </Col>
        </Row>

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="card" style={{ margin: "10px 0 0 0" }}>
              <div className="card-body" style={{ paddingBottom: "14px" }}>
                <div>
                  <Col>
                    <Typography.Text
                      style={{
                        fontSize: "10px",
                        color: "grey",
                      }}
                    >
                      Opportunity:
                    </Typography.Text>
                    <Typography.Title
                      style={{
                        color: "rgb(105 105 105)",
                        marginTop: "-6px",
                        marginBottom: "-12px",
                      }}
                      level={2}
                    >
                      {props.location.state.title}
                    </Typography.Title>
                  </Col>
                  <div className="float-end" style={{ marginTop: "-50px" }}>
                    <UpdateOpportunity data={props.location.state} />
                  </div>

                  <Divider />
                </div>

                <Row gutter={[16, 20]}>
                  <Col className="gutter-row" span={6}>
                    <DetailItem
                      title={"Client Name"}
                      detail={props.location.state.client_name}
                    />
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <DetailItem
                      title={"Status"}
                      detail={props.location.state.status}
                    />
                  </Col>

                  <Col className="gutter-row" span={6}>
                    <DetailItem
                      title={"Source Requestor"}
                      detail={props.location.state.source_requestor}
                    />
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <DetailItem
                      title={"Assigned To"}
                      detail={props.location.state.assigned_to}
                    />
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <DetailItem
                      title={"Creator's Name"}
                      detail={props.location.state.created_by_name}
                    />
                  </Col>

                  <Col className="gutter-row" span={6}>
                    <DetailItem
                      title={"Creator's Email Address"}
                      detail={props.location.state.created_by_email}
                    />
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <DetailItem
                      title={"Updater's Name"}
                      detail={props.location.state.updated_by_name}
                    />
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <DetailItem
                      title={"Updater's Email Address"}
                      detail={props.location.state.updated_by_email}
                    />
                  </Col>

                  <Col className="gutter-row" span={12}>
                    <DetailItem
                      title={"Comments"}
                      detail={props.location.state.comments}
                    />
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <DetailItem
                      title={"Description"}
                      detail={props.location.state.description}
                    />
                  </Col>
                </Row>
                {/* <Descriptions
                  title={`Opportunity Details: ${props.location.state.title}`}
                  bordered
                >
                  <Descriptions.Item label="Client Name: ">
                    {props.location.state.client_name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Status: ">
                    {props.location.state.status}
                  </Descriptions.Item>
                  <Descriptions.Item label="Assigned To: ">
                    {props.location.state.assigned_to}
                  </Descriptions.Item>
                  <Descriptions.Item label="Created By Name: ">
                    {props.location.state.created_by_name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Created By Email: ">
                    {props.location.state.created_by_email}
                  </Descriptions.Item>
                  <Descriptions.Item label="Comments: ">
                    {props.location.state.comments}
                  </Descriptions.Item>
                  <Descriptions.Item label="Description: ">
                    {props.location.state.description}
                  </Descriptions.Item>
                  <Descriptions.Item label="Source Requestor: ">
                    {props.location.state.source_requestor}
                  </Descriptions.Item>
                </Descriptions> */}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
