import React from "react";
import { Col, Row } from "antd";
import Breadcrumb from "../../../BreadCrumbs";
import AuditsList from "./AuditsList";
import { useLocation } from "react-router-dom";

function AuditsDashboard(props) {
  const { state } = useLocation();
  return (
    <div className="container dashboard statistics-card">
      <Row className="title-row">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <div className="pages-header">Audits</div>
          <Breadcrumb location={props.location} />
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <AuditsList opportunity={state} />
        </Col>
      </Row>
    </div>
  );
}

export default AuditsDashboard;
