import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "4925a7ce-6f96-4406-a254-7e618a8fb537", // This is the ONLY mandatory field that you need to supply.
    authority:
      "https://login.microsoftonline.com/yashtechnologies841.onmicrosoft.com", // Defaults to "https://login.microsoftonline.com/common"
    redirectUri:
      window.location.hostname === "localhost"
        ? "http://localhost:3000/dashboard"
        : "https://opportunity-tracker.yashaccelerator.link/dashboard", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri:
      window.location.hostname === "localhost"
        ? "http://localhost:3000"
        : "https://opportunity-tracker.yashaccelerator.link", // Indicates the page to navigate after logout.
    clientCapabilities: ["CP1"], // this lets the resource owner know that this client is capable of handling claims challenge.
    // scopes: ["499b84ac-1321-427f-aa17-267ca6975798/user_impersonation"],
    scopes: ["https://graph.microsoft.com/.default"],
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      /**
       * Below you can configure MSAL.js logs. For more information, visit:
       * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
       */
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.log(message);
        }
      },
    },
  },
};
