import React from "react";
import { Col, Row } from "antd";
import Breadcrumb from "./BreadCrumbs";
import OpportunityTypePi from "./opportunity/oppComponents/Charts/OpportunityTypePi";
import OpportunityStatus from "./opportunity/oppComponents/Charts/OpportunityStatus";
import OpportunityBar from "./opportunity/oppComponents/Charts/OpportunityBar";
import { GraphDashboard } from "./dashboard/GraphDashboad";
import CountBillCards from "./dashboard/CountBillCards";
import OpportunityBarQuarterly from "./opportunity/oppComponents/Charts/OpportunityBarQuarterly";
import OpportunityBarWeekly from "./opportunity/oppComponents/Charts/OpportunityBarWeekly";

export default function Dashboard(props) {
  return (
    <>
      <div className="container">
        <Row className="title-row">
          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
            <Breadcrumb location={props.location} />
          </Col>
        </Row>
        <div className="container-fluid statistics-card ">
          <GraphDashboard />
          <span className="div-dash">
            <CountBillCards />
          </span>
        </div>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className="card" style={{ height: "530px" }}>
              <div className="card-body">
                <OpportunityTypePi />
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className="card" style={{ height: "530px" }}>
              <div className="card-body">
                <OpportunityStatus />
              </div>
            </div>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="card" style={{ height: "530px" }}>
              <div className="card-body">
                <OpportunityBar />
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="card" style={{ height: "580px" }}>
              <div className="card-body">
                <OpportunityBarQuarterly />
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="card">
              <div className="card-body">
                <OpportunityBarWeekly />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
