import React from "react";
import { routes } from "./util/constants";
import { Route, Routes, useLocation } from "react-router-dom";
import AdminDashboard from "./ui-components/dashboard/AdminDashboard";
import Home from "./ui-components/Home";

export default function NavRoutes() {
  let location = useLocation();
  return (
    <Routes location={location}>
      <Route exact path={routes.HOME} element={<Home />} />
      <Route
        exact
        path={routes.ADMIN_DASHBOARD}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.OPPORTUNITY_DASHBOARD}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.OPPORTUNITY_DETAILS}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.OPPORTUNITY_DOCUMENTS}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.OPPORTUNITY_AUDITS}
        element={<AdminDashboard location={location} />}
      />
    </Routes>
  );
}
