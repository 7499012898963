import axios from "axios";
import { opportunityActions } from "./opportunity";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getOpportunityList = createAsyncThunk(
  "getAllOppThunk",
  async () => {
    try {
      const res = await axios.get(
        "https://bgzz8fj81j.execute-api.ap-south-1.amazonaws.com/dev/opportunity/getall"
      );
      return res.data.body;
    } catch (error) {
      console.log("error ", error);
    }
  }
);
export const createOpportunity = createAsyncThunk(
  "createOppThunk",
  async (data) => {
    try {
      const res = await axios.post(
        "https://bgzz8fj81j.execute-api.ap-south-1.amazonaws.com/dev/opportunity",
        data
      );
      return res.data.body;
    } catch (error) {
      console.log("error ", error);
    }
  }
);
export const updateOpportunity = createAsyncThunk(
  "updateOppThunk",
  async (data) => {
    try {
      const res = await axios.put(
        "https://bgzz8fj81j.execute-api.ap-south-1.amazonaws.com/dev/opportunity",
        data
      );
      console.log("res from update reducer", res);
      return res.data.body;
    } catch (error) {
      console.log("error ", error);
    }
  }
);

// export const getOpportunityList = () => {
//   return async (dispatch) => {
//     await axios
//       .get(
//         "https://bgzz8fj81j.execute-api.ap-south-1.amazonaws.com/dev/opportunity/getall"
//       )
//       .then((resp) => {
//         dispatch(opportunityActions.getOpportunityList(resp.data.body));
//       })
//       .catch((error) => console.log("#", error));
//   };
// };
// export const createOpportunity = (data) => {
//   return async (dispatch) => {
//     await axios
//       .post(
//         "https://bgzz8fj81j.execute-api.ap-south-1.amazonaws.com/dev/opportunity",
//         data
//       )
//       .then((resp) => {
//         dispatch(opportunityActions.addSingleOpportunity(resp.data.body));
//       })
//       .catch((error) => console.log("#", error));
//   };
// };

// export const updateOpportunity = (data) => {
//   return async (dispatch) => {
//     await axios
//       .put(
//         "https://bgzz8fj81j.execute-api.ap-south-1.amazonaws.com/dev/opportunity",
//         data
//       )
//       .then((resp) => {
//         dispatch(opportunityActions.updateOpportunity(resp.data.body));
//       })
//       .catch((error) => console.log("#", error));
//   };
// };

export const getAllOpportunityStatusTypeTeam = () => {
  return async (dispatch) => {
    await axios
      .get(
        "https://bgzz8fj81j.execute-api.ap-south-1.amazonaws.com/dev/master-data"
      )
      .then((resp) => {
        dispatch(opportunityActions.getAllStatusTypeTeam(resp.data.body));
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
};
export const getOpportunityDocumentsList = (id) => {
  return async (dispatch) => {
    await axios
      .get(
        `https://bgzz8fj81j.execute-api.ap-south-1.amazonaws.com/dev/opportunity?opportunity_id=${id}`
      )
      .then((resp) => {
        console.log("#respppppppp red", resp);
        dispatch(opportunityActions.getOpportunityDocumentsList(resp.data));
      })
      .catch((error) => console.log("#", error));
  };
};
