import { configureStore } from "@reduxjs/toolkit";
import opportunityReducer from "./opportunity/opportunity";

const store = configureStore({
  reducer: {
    opportunity: opportunityReducer,
  },
});

export default store;
