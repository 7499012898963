import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Space, Row, Table, Tooltip, Col, Input } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import axios from "axios";
import { saveAs } from "file-saver";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";

const { Column } = Table;

function ListDocuments(props) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.opportunity.opportunityDocsList);

  const downloadDocument = (data) => {
    let requestObjForUploadUrl = {
      request_type: "GET",
      path: data.path,
    };
    axios
      .post(
        `https://bgzz8fj81j.execute-api.ap-south-1.amazonaws.com/dev/document/get-presigned-url`,
        requestObjForUploadUrl
      )
      .then((response) => {
        saveAs(response.data.body.url); // Put your image url here.
      });
  };

  //   useEffect(() => {
  //     dispatch(getOpportunityList());
  //   }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  return (
    <div className="card" style={{ overflow: "scroll", margin: "10px 0 0 0" }}>
      <div className="card-body">
        <Table
          dataSource={data[0]?.documents}
          pagination={{
            className: "pagination",
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10"],
          }}
          responsive
          // loading={true}
        >
          <Column
            title="Name"
            key="document_name"
            dataIndex="document_name"
            // {...getColumnSearchProps("name")}
          />
          <Column
            title="Version"
            key="version"
            dataIndex="version"
            // {...getColumnSearchProps("name")}
          />
          <Column
            title="Created By Name"
            key="created_by_name"
            dataIndex="created_by_name"
            {...getColumnSearchProps("created_by_name")}
          />
          <Column
            title="Created By Email"
            key="created_by_email"
            dataIndex="created_by_email"
            {...getColumnSearchProps("created_by_email")}
          />
          {props.flag === "Upload" ? (
            <Column
              title="Created At"
              key="createdAt"
              dataIndex="createdAt"
              render={(record) =>
                moment(record).format("MMMM Do YYYY, h:mm:ss a")
              }
              sorter={(a, b) =>
                new Date(moment(a.createdAt).format("LLL")) -
                new Date(moment(b.createdAt).format("LLL"))
              }
            />
          ) : (
            <Column
              title="Updated At"
              key="updatedAt"
              dataIndex="updatedAt"
              render={(record) =>
                moment(record).format("MMMM Do YYYY, h:mm:ss a")
              }
              sorter={(a, b) =>
                new Date(moment(a.createdAt).format("LLL")) -
                new Date(moment(b.createdAt).format("LLL"))
              }
            />
          )}

          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <Row gutter={[16, 8]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Tooltip title="Download">
                    <Button
                      size="small"
                      shape="circle"
                      icon={<DownloadOutlined />}
                      onClick={() => downloadDocument(record)}
                      style={{
                        color: "white",
                        // background: "#097969",
                        background: "rgb(9 121 105 / 94%)",
                      }}
                    />
                  </Tooltip>
                </Col>
              </Row>
            )}
          />
        </Table>
      </div>
    </div>
  );
}

export default ListDocuments;
