import React, { Component } from "react";
import {
  ArrowRightOutlined,
  FileDoneOutlined,
  FileAddOutlined,
  FileSyncOutlined,
  AuditOutlined,
} from "@ant-design/icons";
import { Button, Row, Col, Skeleton } from "antd";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import { routes } from "../../util/constants";

export default class CountBillCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardData: [],
    };
  }
  componentDidMount() {
    const defaultMonth = moment(new Date()).month() + 1;
    const defaultYear = moment(new Date()).year();

    this.getDashboardData(defaultMonth, defaultYear);
  }

  getDashboardData = (month, year) => {
    const url =
      "https://bgzz8fj81j.execute-api.ap-south-1.amazonaws.com/dev/dashboard/status-cards-data";
    axios
      .get(url)
      .then((res) => {
        this.setState({
          cardData: res.data.body,
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  render() {
    return (
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="count-cards mt-3">
            <Row gutter={[24, 8]}>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <div className="">
                  <div className="card-body2">
                    <div className="details">
                      <AuditOutlined />
                      <span className="count ms-3">
                        <span className="name-cloud">Created</span>
                        <h6>
                          {this.state.cardData.length == 0 ? (
                            <Skeleton.Button size="small" active={true} />
                          ) : (
                            <p className="text-white">
                              {this.state.cardData.map((ele) => {
                                if (ele.status == "CREATED") {
                                  return ele.opp_count == 0 ||
                                    ele.opp_count == null ||
                                    ele.opp_count == undefined
                                    ? 0
                                    : ele.opp_count || 0;
                                }
                              })}
                            </p>
                          )}
                        </h6>
                      </span>
                    </div>
                    <div className="buttons">
                      <Link to={routes.OPPORTUNITY_DASHBOARD}>
                        <Button size="large" type="text" className="text-white">
                          <ArrowRightOutlined />
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <div className="">
                  <div className="card-body3">
                    <div className="details">
                      <FileSyncOutlined />
                      <span className="count ms-3">
                        <span className="name-cloud">In Progress</span>
                        <h6>
                          {this.state.cardData.length === 0 ? (
                            <Skeleton.Button size="small" active={true} />
                          ) : (
                            <p className="text-white">
                              {this.state.cardData.map((ele) => {
                                if (ele.status === "IN PROGRESS") {
                                  return ele.opp_count == 0 ||
                                    ele.opp_count == null ||
                                    ele.opp_count == undefined
                                    ? 0
                                    : ele.opp_count || 0;
                                }
                              })}
                            </p>
                          )}
                        </h6>
                      </span>
                    </div>
                    <div className="arrow-button">
                      <Link to={routes.OPPORTUNITY_DASHBOARD}>
                        <Button size="large" type="text" className="text-white">
                          <ArrowRightOutlined />
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <div className="">
                  <div className="card-body1">
                    <div className="details">
                      <FileDoneOutlined />
                      <span className="count ms-3">
                        <span className="name-cloud">Completed</span>

                        <h6>
                          {this.state.cardData.length == 0 ? (
                            <Skeleton.Button size="small" active={true} />
                          ) : (
                            <p className="text-white">
                              {this.state.cardData.map((ele) => {
                                if (ele.status == "COMPLETED") {
                                  return ele.opp_count == 0 ||
                                    ele.opp_count == null ||
                                    ele.opp_count == undefined
                                    ? 0
                                    : ele.opp_count || 0;
                                }
                              })}
                            </p>
                          )}
                        </h6>
                      </span>
                    </div>
                    <div className="buttons">
                      <Link to={routes.OPPORTUNITY_DASHBOARD}>
                        <Button size="large" type="text" className="text-white">
                          <ArrowRightOutlined />
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  }
}
