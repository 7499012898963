import {
  Col,
  Row,
  Button,
  Modal,
  Form,
  Input,
  Tooltip,
  Select,
  message,
  Tag,
} from "antd";

import { CloseOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { createOpportunity } from "../../redux/opportunity/opportunity-actions";
import { getAllOpportunityStatusTypeTeam } from "../../redux/opportunity/opportunity-actions";
import { Option } from "antd/lib/mentions";

const { TextArea } = Input;
function AddOpportunity(props) {
  const [form] = Form.useForm();
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [teamId, setTeamId] = useState("");
  const [chips, setChips] = useState([]);
  const [opportunityType, setOpportunityType] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("CREATED");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectPriority, setSelectPriority] = useState("");
  const [title, setTitle] = useState("");

  const [fields, setFields] = useState({
    emailError: null,
    title: "",
    description: "",
    source_requestor: "",
    assigned_to: "",
    status: "CREATED",
    comments: "",
    client_name: "",
    technology: "",
  });
  useEffect(() => {
    dispatch(getAllOpportunityStatusTypeTeam());
  }, []);
  const data = useSelector((state) => state.opportunity);

  const allStatus = data.allStatus;
  const allTeam = data.allteams;
  const allOpportunityType = data.opportunityType;
  const allRegion = data.allRegion;

  const handleKeyDown = (e) => {
    if (["Enter", "Tab", ",", " "].includes(e.key)) {
      e.preventDefault();
      const value = fields.assigned_to.trim();
      if (value && isValid(value)) {
        const newChips = [...chips, fields.assigned_to];
        setChips(newChips);
        const field = { ...fields };
        field["assigned_to"] = "";
        setFields(field);
      }
    }
  };
  // const handleTitleChange = (e) => {
  //   setTitle(e.target.value);
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFields({ ...fields, [name]: value });
  };
  const isValid = (email) => {
    let emailError = null;

    if (isInList(email)) {
      emailError = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      emailError = `${email} is not a valid email address.`;
    }

    if (emailError) {
      setFields({ emailError });

      return false;
    }

    return true;
  };
  const handleEmailChange = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
      emailError: null,
    });
  };

  const isInList = (email) => {
    return chips.includes(email);
  };

  const isEmail = (email) => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  };
  const onKeyDownSubmit = (event) => {
    if (event.keyCode === 13) {
      //13 is the key code for Enter
      event.preventDefault();
    }
  };
  const handleChipClose = (chip) => {
    const updatedChips = chips.filter((c) => c !== chip);
    setChips(updatedChips);
  };

  const onSubmit = (e) => {
    const allEmails = chips;
    const latestAllEmails = allEmails.join().toString();

    let finalData = {
      opportunity_type_id: opportunityType,
      priority_level: selectPriority,
      team_id: teamId,
      title: fields.title,
      technology: fields.technology,
      description: fields.description,
      source_requestor: instance.getActiveAccount().username,
      assigned_to: latestAllEmails,
      status: selectedStatus,
      comments: fields.comments,
      client_name: fields.client_name,
      region: selectedRegion,
      created_by_email: instance.getActiveAccount()
        ? instance.getActiveAccount().username
        : "",
      created_by_name: instance.getActiveAccount()
        ? instance.getActiveAccount().name
        : "",
      updated_by_email: instance.getActiveAccount()
        ? instance.getActiveAccount().username
        : "",
      updated_by_name: instance.getActiveAccount()
        ? instance.getActiveAccount().name
        : "",
    };

    dispatch(createOpportunity(finalData))
      .then((res) => {
        setOpen(false);
        message.success("Opportunity Created!");
      })
      .catch((err) => {
        console.log("error", err);
      });

    form.resetFields();
    setChips([]);
    setFields({
      description: "",
      comments: "",
    });
  };

  const showModal = () => {
    setOpen(true);
  };
  const handleModalCancel = () => {
    setOpen(false);
  };
  return (
    <Row>
      <Col span={24}>
        <div className="add-button">
          <Button
            className="btn-admin btn-subCategory"
            type="primary"
            onClick={showModal}
          >
            Create
          </Button>
          <Modal
            open={open}
            // onOk={handleOk}
            onCancel={handleModalCancel}
            title="Create Opportunity"
            style={{ top: 20 }}
            width={650}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose={true}
          >
            <Form
              name="Create Opportunity"
              className="Create Opportunity"
              form={form}
              onKeyDown={onKeyDownSubmit}
              onFinish={onSubmit}
              initialValues={{
                remember: true,
              }}
            >
              <Row gutter={[8, 8]}>
                <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                  <Tooltip title="Enter Opportunity Title">
                    <Form.Item
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter title!",
                        },
                      ]}
                    >
                      <Input
                        autoFocus
                        placeholder="Enter Title"
                        name="title"
                        value={fields.title}
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                  <Tooltip title="Enter Client Name">
                    <Form.Item
                      name="client_name"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Client Name!",
                        },
                      ]}
                    >
                      <Input
                        autoFocus
                        placeholder="Enter Client Name"
                        name="client_name"
                        value={fields.client_name}
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                  <Tooltip title="Source Requester">
                    <Form.Item name="source_requestor">
                      <Input
                        autoFocus
                        name="source_requestor"
                        defaultValue={instance.getActiveAccount().username}
                        // onChange={handleChange}
                        disabled
                        readOnly={true}
                      />
                    </Form.Item>
                  </Tooltip>
                </Col>

                <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                  <Form.Item name="Priority" noStyle>
                    <Tooltip title="Select Priority">
                      <Select
                        placeholder=" Select Priority"
                        style={{
                          width: "100%",
                        }}
                        onChange={(select) => {
                          setSelectPriority(select);
                        }}
                      >
                        {["HIGH", "MEDIUM", "LOW"]?.map((item) => {
                          return <Option value={item}>{item}</Option>;
                        })}
                      </Select>
                    </Tooltip>
                  </Form.Item>
                </Col>

                <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                  <Form.Item name="Status" noStyle>
                    <Tooltip title="Select  Status">
                      <Select
                        placeholder="Select Status"
                        style={{
                          width: "100%",
                        }}
                        onChange={(select) => {
                          setSelectedStatus(select);
                        }}
                        defaultValue={fields.status}
                        disabled
                      >
                        {allStatus?.map((item) => {
                          return (
                            <Option value={item.opportunity_status}>
                              {item.opportunity_status}
                            </Option>
                          );
                        })}
                      </Select>
                    </Tooltip>
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                  <Tooltip title="Enter Technology">
                    <Form.Item
                      name="technology"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Technology!",
                        },
                      ]}
                    >
                      <Input
                        autoFocus
                        placeholder="Enter Technology"
                        name="technology"
                        value={fields.technology}
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </Tooltip>
                </Col>

                <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                  <Form.Item name="teamname" noStyle>
                    <Tooltip title="Select Team">
                      <Select
                        placeholder="Select Team"
                        style={{
                          width: "100%",
                        }}
                        onChange={(select) => {
                          setTeamId(select);
                        }}
                      >
                        {allTeam?.map((item) => {
                          return (
                            <Option value={item.id}>{item.team_name}</Option>
                          );
                        })}
                      </Select>
                    </Tooltip>
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                  <Form.Item name="opportunitytype" noStyle>
                    <Tooltip title="Select Opportunity">
                      <Select
                        placeholder=" Select Opportunity Type"
                        style={{
                          width: "100%",
                        }}
                        onChange={(select) => {
                          setOpportunityType(select);
                        }}
                      >
                        {allOpportunityType?.map((item) => {
                          return (
                            <Option value={item.id}>
                              {item.opportunity_type_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Tooltip>
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                  <Form.Item name="Regions" noStyle>
                    <Tooltip title="Select Region">
                      <Select
                        placeholder=" Select Region"
                        style={{
                          width: "100%",
                        }}
                        onChange={(select) => {
                          setSelectedRegion(select);
                        }}
                      >
                        {allRegion?.map((item) => {
                          return (
                            <Option value={item.region}>{item.region}</Option>
                          );
                        })}
                      </Select>
                    </Tooltip>
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ marginTop: "20px" }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div>
                    {chips.map((chip) => (
                      <Tag
                        key={chip}
                        closable
                        onClose={() => {
                          handleChipClose(chip);
                        }}
                        style={{
                          marginRight: "8px",
                          marginBottom: "8px",
                          borderRadius: "10px",
                          height: "30px",
                        }}
                      >
                        {chip}
                      </Tag>
                    ))}
                  </div>
                  <Tooltip title="Assignee">
                    <Form.Item
                      name="assigned_to"
                      rules={[
                        {
                          required: chips.length ? false : true,
                          message: "Enter a Email",
                        },
                      ]}
                    >
                      <Input
                        style={{
                          border: fields.emailError ? "1px solid red" : null,
                        }}
                        autoFocus
                        placeholder="Enter Assignee Email"
                        name="assigned_to"
                        onKeyDown={handleKeyDown}
                        value={fields.assigned_to}
                        onChange={handleEmailChange}
                      />
                      {fields.emailError && (
                        <p className="error" style={{ color: "red" }}>
                          {fields.emailError}
                        </p>
                      )}
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>

              <Row
                gutter={[8, 8]}
                style={{ marginTop: fields.emailError ? "-16px" : null }}
              >
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextArea
                    autoFocus
                    placeholder="Type Description..."
                    name="description"
                    value={fields.description}
                    onChange={handleChange}
                    rows={4}
                  />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextArea
                    autoFocus
                    placeholder="Type Comments..."
                    name="comments"
                    value={fields.comments}
                    onChange={handleChange}
                    rows={4}
                  />
                </Col>
              </Row>

              <Form.Item style={{ float: "right", margin: "10px 0" }}>
                <Button
                  className="close-modal"
                  style={{ margin: "0 10px 0 0" }}
                  onClick={handleModalCancel}
                >
                  Close
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="ok-modal"
                  loading={data.createLoading}
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </Col>
    </Row>
  );
}

export default AddOpportunity;
