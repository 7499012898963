import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getOpportunityList,
  getOpportunityDocumentsList,
} from "../../redux/opportunity/opportunity-actions";
import {
  Button,
  Space,
  Row,
  Table,
  Tooltip,
  Col,
  Input,
  Typography,
  Badge,
} from "antd";
import {
  FileTextOutlined,
  SearchOutlined,
  EyeOutlined,
  AuditOutlined,
} from "@ant-design/icons";
import UpdateOpportunity from "../../redux/opportunity/UpdateOpportunity";
import { useNavigate } from "react-router";
import { routes } from "../../util/constants";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { Link, NavLink } from "react-router-dom";

const { Column } = Table;

function ListOpportunities(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [page, setPage] = React.useState(1);
  const searchInput = useRef(null);
  const data = useSelector((state) => state.opportunity.opportunityList);
  console.log("dataaaaaa", data);

  useEffect(() => {
    dispatch(getOpportunityList());
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const navigateToDetails = (data) => {
    console.log("called1111111", data);

    navigate(routes.OPPORTUNITY_DETAILS, { state: data });
  };
  const navigateToDocumentsPage = (data) => {
    dispatch(getOpportunityDocumentsList(data.id));
    navigate(routes.OPPORTUNITY_DOCUMENTS, { state: { data, flag: "Upload" } });
  };

  const navigateToAuditsPage = (data) => {
    // dispatch(getOpportunityDocumentsList(data.id));
    navigate(routes.OPPORTUNITY_AUDITS, { state: data });
  };

  return (
    <div className="card" style={{ overflow: "scroll", margin: "10px 0 0 0" }}>
      <div className="card-body">
        <Table
          // rowKey={(record) => record.id}
          // rowSelection={{
          //   type: "radio",
          //   ...rowSelection,
          // }}
          loading={data.length > 0 ? false : true}
          dataSource={data}
          scroll={{ y: 400 }}
          pagination={{
            className: "pagination",
            defaultPageSize: 10,
            // showSizeChanger: true,
            // pageSizeOptions: ["10"],
            onChange(current) {
              setPage(current);
            },
          }}
          responsive
          // loading={true}
        >
          <Column
            title="S/N"
            key="index"
            render={(value, item, index) => (page - 1) * 10 + index + 1}
            width={60}
          />
          <Column
            title="Opportunity Name"
            // dataIndex="title"
            key="title"
            {...getColumnSearchProps("title")}
            render={(record) => (
              <Typography.Text
                className="opportunityTitle"
                onClick={() => {
                  navigateToDetails(record);
                }}
              >
                {record?.title}
              </Typography.Text>
            )}
            width={125}
          />

          <Column
            title="Opportunity Type"
            key="opportunity_type"
            render={(record) => record?.opportunity_type?.opportunity_type_name}
            width={120}
          />
          <Column
            title="Priority"
            key="priority_level"
            {...getColumnSearchProps("priority_level")}
            render={(record) =>
              record?.priority_level ? record.priority_level : "NA"
            }
            width={95}
          />

          <Column
            title="Status"
            dataIndex="status"
            key="status"
            {...getColumnSearchProps("status")}
            width={115}
          />
          <Column
            title="Assigned_to"
            key="assigned_to"
            {...getColumnSearchProps("assigned_to")}
            render={(record) =>
              record?.assigned_to?.map((email) => email + " ")
            }
            width={125}
          />
          <Column
            title="Technology"
            key="technology"
            {...getColumnSearchProps("technology")}
            render={(record) => (record?.technology ? record.technology : "NA")}
            width={120}
          />

          <Column
            title="Created On"
            key="createdAt"
            dataIndex="createdAt"
            render={(record) =>
              moment(record).format("MMMM Do YYYY, h:mm:ss a")
            }
            sorter={(a, b) =>
              new Date(moment(a.createdAt).format("LLL")) -
              new Date(moment(b.createdAt).format("LLL"))
            }
            width={115}
          />

          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <Row>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Tooltip title="More Details">
                    <Button
                      size="small"
                      shape="circle"
                      icon={<EyeOutlined />}
                      onClick={(event) => navigateToDetails(record)}
                      style={{
                        color: "white",
                        background: "#6495ED",
                      }}
                    />
                  </Tooltip>
                </Col>
                <UpdateOpportunity data={record} />

                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Tooltip title="Documents">
                    <Badge
                      count={record?.documents?.length}
                      showZero
                      size="small"
                    >
                      <Button
                        size="small"
                        shape="circle"
                        icon={<FileTextOutlined />}
                        onClick={(event) => navigateToDocumentsPage(record)}
                        style={{
                          color: "white",
                          background: "rgb(9 121 105 / 94%)",
                        }}
                      />
                    </Badge>
                  </Tooltip>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Tooltip title="Audits">
                    <Button
                      size="small"
                      shape="circle"
                      icon={<AuditOutlined />}
                      onClick={(event) => navigateToAuditsPage(record)}
                      style={{
                        color: "white",
                        background: "#f45759",
                      }}
                    />
                  </Tooltip>
                </Col>

                {/**<a>
                <UpdateAgeGroup ageGroups={text} />
              </a> */}
              </Row>
            )}
            width={135}
          />
        </Table>
      </div>
    </div>
  );
}

export default ListOpportunities;
