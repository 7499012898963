import { Col, Row, Button, Modal, Form, message, Spin } from "antd";
import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import pdfImg from "../../../../assests/img/pdf-img.webp";
import PreviewUploadedImage from "./PreviewUploadedImage";
import axios from "axios";
import { getOpportunityDocumentsList } from "../../../../redux/opportunity/opportunity-actions";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function AddDocument(props) {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.opportunity.opportunityDocsList);
  const [open, setOpen] = useState(false);
  const [inputFile, setInputFile] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [fileName, setFileName] = useState("");
  const [uploadDocStatus, setUploadDocStatus] = useState(false);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isvideo = file.type === "video/mp4";
    const ispdf = file.type === "application/pdf";
    const isLt5M = file.size / 1024 / 1024 < 5;
    const isLt16M = file.size / 1024 / 1024 < 16;
    const isLt10 = file.size / 1024 / 1024 < 10;

    if (isJpgOrPng) {
      if (!isLt5M) {
        message.error("Image must smaller than 5MB!");
        return false;
      }
    } else if (isvideo) {
      if (!isLt16M) {
        message.error("Video must smaller than 16MB!");
        return false;
      }
    } else if (ispdf) {
      if (!isLt10) {
        message.error("PDF must smaller than 10MB!");
        return false;
      }
    } else {
      message.error("You can only upload JPG/PNG/MP4 file!");
      return false;
    }
    return true;
  };

  const handleFileChange = async (file) => {
    let resp = await beforeUpload(file.target.files[0]);
    if (resp) {
      // if (data[0]?.documents.length > 0) {
      //   let flag = undefined;
      //   data[0]?.documents.filter((doc) => {
      //     if (doc.document_name == file.target.files[0].name) {
      //       flag = true;
      //     }
      //   });
      //   if (flag) {
      //     message.error("Document with same name already uploaded!");
      //     flag = undefined;
      //   } else {
      setFileName(file.target.files[0].name);
      file.target.files[0].preview = await getBase64(file.target.files[0]);
      let newFileObj = [];
      newFileObj.push(file.target.files[0]);
      setFileList(newFileObj);
      setFile(URL.createObjectURL(file.target.files[0]));
      setFileType(file.target.files[0].type);
      // flag = undefined;
      // }
      // }
    }
  };

  const onSubmit = async () => {
    setUploadDocStatus(true);
    if (fileList.length !== 0) {
      let finalUrl = "";
      let url = fileList[0].preview.split("base64,");
      finalUrl = finalUrl.concat(url[1]);
      let requestObjForUploadUrl = {
        opportunity_id: props.opportunityData.id,
        document_type: fileType,
        request_type: "PUT",
        document_name: fileName,
        created_by_email: instance.getActiveAccount()
          ? instance.getActiveAccount().username
          : "",
        created_by_name: instance.getActiveAccount()
          ? instance.getActiveAccount().name
          : "",
      };
      let resp = await axios.post(
        `https://bgzz8fj81j.execute-api.ap-south-1.amazonaws.com/dev/document/get-presigned-url`,
        requestObjForUploadUrl
      );
      const uploadResponse = await axios.put(resp.data.body.url, fileList[0]);
      if (uploadResponse.status === 200) {
        setOpen(false);
        setUploadDocStatus(false);
        removeUploadedFile();
        message.success("Document uploaded!");
        dispatch(getOpportunityDocumentsList(props.opportunityData.id));
      }
    }
  };

  const removeUploadedFile = () => {
    setFileList([]);
    setFile(null);
    setFileType("");
    setFileName("");
  };
  const showModal = () => {
    setOpen(true);
  };
  const handleModalCancel = () => {
    setOpen(false);
    setUploadDocStatus(false);
    removeUploadedFile();
  };
  return (
    <Row>
      <Col span={24}>
        <div className="add-button">
          <Button
            className="btn-admin btn-subCategory"
            type="primary"
            onClick={showModal}
          >
            Upload
          </Button>
          <Modal
            open={open}
            // onOk={handleOk}
            onCancel={handleModalCancel}
            title="Upload Document"
            style={{ top: 20 }}
            // width={1250}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose={true}
          >
            <Form
              name="Upload Document"
              className="Upload Document"
              onFinish={onSubmit}
              initialValues={{
                remember: true,
              }}
            >
              <Row gutter={[8, 8]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="upload" valuePropName="fileList">
                    {file ? (
                      <Row gutter={[8, 8]}>
                        {(fileType == "image/png" ||
                          fileType == "image/jpeg") && (
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <div style={{ textAlign: "center" }}>
                              <img width={125} height={125} src={file} />
                            </div>
                          </Col>
                        )}
                        {fileType == "video/mp4" && (
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <div style={{ textAlign: "center" }}>
                              <video width="125" height="125" controls>
                                <source src={file} type="video/mp4" />
                              </video>
                            </div>
                          </Col>
                        )}
                        {fileType == "application/pdf" && (
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <div style={{ textAlign: "center" }}>
                              <img width={125} height={125} src={pdfImg} />
                            </div>
                          </Col>
                        )}
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <Row>
                            <div style={{ margin: "0 10px 0 auto" }}>
                              {fileType !== "video/mp4" && (
                                <PreviewUploadedImage
                                  file={file}
                                  fileType={fileType}
                                />
                              )}
                            </div>
                            <div style={{ margin: "0 auto 0 0" }}>
                              <Button onClick={removeUploadedFile} danger>
                                <DeleteOutlined />
                              </Button>
                            </div>
                          </Row>
                        </Col>
                        <div style={{ margin: "0 auto" }}>
                          <label>
                            <h4>{fileName}</h4>
                          </label>
                        </div>
                      </Row>
                    ) : (
                      <div className="text-center">
                        <label
                          for="inputFile"
                          style={{
                            fontSize: "25px",
                            textAlign: "-webkit-center",
                          }}
                        >
                          <div class="drag-area ">
                            <h6>Upload</h6> <PlusOutlined />
                            <input
                              class="form-control"
                              type="file"
                              id="inputFile"
                              name="inputFile"
                              value={inputFile}
                              onChange={handleFileChange}
                              hidden
                              accept="image/png, image/jpeg,image/jpg,application/pdf,video/mp4"
                            />
                          </div>
                        </label>
                      </div>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item style={{ float: "right", margin: "10px 0" }}>
                <Button
                  className="close-modal"
                  style={{ margin: "0 10px 0 0" }}
                  onClick={handleModalCancel}
                >
                  Close
                </Button>
                {!uploadDocStatus ? (
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="ok-modal"
                    disabled={fileList.length ? false : true}
                  >
                    Save
                  </Button>
                ) : (
                  <Button type="primary" className="ok-modal" disabled>
                    Saving...
                    <Spin size="medium" />
                  </Button>
                )}
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </Col>
    </Row>
  );
}

export default AddDocument;
