import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Button, Row, Table, Tooltip, Col, Space, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { FileTextOutlined } from "@ant-design/icons";
import { routes } from "../../../../util/constants";
import Highlighter from "react-highlight-words";
import axios from "axios";
import moment from "moment";
import { getOpportunityDocumentsList } from "../../../../redux/opportunity/opportunity-actions";

const { Column } = Table;
function AuditsList(props) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [data, setData] = useState([]);
  const searchInput = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateToDocumentsPage = (data) => {
    dispatch(getOpportunityDocumentsList(data.id));
    navigate(routes.OPPORTUNITY_DOCUMENTS, { state: { data, flag: "Audit" } });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const getAuditsByOpportunityId = () => {
    let params = {
      opportunity_id: props.opportunity.id,
    };
    const url =
      "https://bgzz8fj81j.execute-api.ap-south-1.amazonaws.com/dev/opportunity/opportunity-audit";
    axios
      .get(url, { params: params })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log("OpportunityAudits-Error============>", error);
      });
  };

  useEffect(() => {
    getAuditsByOpportunityId();
  }, [props.opportunity]);

  return (
    <div className="card" style={{ overflow: "scroll", margin: "10px 0 0 0" }}>
      <div className="card-body">
        <Table
          //   dataSource={data[0]?.documents}
          dataSource={data}
          loading={data.length > 0 ? false : true}
          pagination={{
            className: "pagination",
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10"],
          }}
          responsive
          scroll={{
            x: 1500,
            y: 300,
          }}
          // loading={true}
        >
          <Column
            title="Created On"
            key="createdAt"
            dataIndex="createdAt"
            render={(record) =>
              moment(record).format("MMMM Do YYYY, h:mm:ss a")
            }
            sorter={(a, b) =>
              new Date(moment(a.createdAt).format("LLL")) -
              new Date(moment(b.createdAt).format("LLL"))
            }
            width={150}
            fixed={"left"}
          />
          <Column
            title="Title"
            key="title"
            dataIndex="title"
            {...getColumnSearchProps("title")}
            fixed={"left"}
            width={100}
          />

          <Column
            title="Opportunity Type"
            key="opportunity_type"
            render={(record) =>
              record.hasOwnProperty("opportunity_type")
                ? record.opportunity_type.opportunity_type_name
                : ""
            }
            width={120}
          />
          <Column
            title="Team Name"
            key="team"
            render={(record) =>
              record.hasOwnProperty("team") ? record.team.team_name : ""
            }
            width={120}
          />
          <Column
            title="Source Requestor"
            key="source_requestor"
            dataIndex="source_requestor"
            width={120}
            {...getColumnSearchProps("source_requestor")}
          />
          <Column
            title="Client Name"
            key="client_name"
            dataIndex="client_name"
            width={145}
            {...getColumnSearchProps("client_name")}
          />

          <Column
            title="Assigned To"
            key="assigned_to"
            dataIndex="assigned_to"
            width={145}
            {...getColumnSearchProps("assigned_to")}
          />
          <Column
            title="Priority"
            key="priority"
            dataIndex="priority_level"
            width={145}
            {...getColumnSearchProps("priority")}
          />
          <Column
            title="Status"
            key="status"
            dataIndex="status"
            width={120}
            {...getColumnSearchProps("status")}
          />
          <Column
            title="Region"
            key="region"
            width={120}
            {...getColumnSearchProps("status")}
            render={(record) => (record?.region ? record.region : "NA")}
          />
          <Column
            title="Technology"
            key="technology"
            width={120}
            {...getColumnSearchProps("status")}
            render={(record) => (record?.technology ? record.technology : "NA")}
          />
          <Column
            title="Creator's Name"
            key="created_by_name"
            dataIndex="created_by_name"
            width={145}
          />
          <Column
            title="Creator's Email"
            key="created_by_email"
            dataIndex="created_by_email"
            width={145}
          />
          <Column
            title="Updater's Name"
            key="updated_by_name"
            dataIndex="updated_by_name"
            width={145}
          />
          <Column
            title="Updater's Email"
            key="updated_by_email"
            dataIndex="updated_by_email"
            width={145}
          />

          <Column
            title="Description"
            key="description"
            dataIndex="description"
            width={160}
            ellipsis={true}
          />
          <Column
            title="Comments"
            key="comments"
            dataIndex="comments"
            width={160}
            ellipsis={true}
          />
          {/**<Column
            title="Action"
            key="action"
            render={(text, record) => (
              <Row gutter={[16, 8]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Tooltip title="Documents Audit">
                    <Button
                      size="small"
                      shape="circle"
                      icon={<FileTextOutlined />}
                      onClick={(event) => navigateToDocumentsPage(record)}
                      style={{
                        color: "white",
                        // background: "#097969",
                        background: "rgb(9 121 105 / 94%)",
                      }}
                    />
                  </Tooltip>
                </Col>
              </Row>
            )}
            width={90}
          /> */}
        </Table>
      </div>
    </div>
  );
}

export default AuditsList;
