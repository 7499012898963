import React from "react";
import { Col, Row } from "antd";
import Breadcrumb from "../../../BreadCrumbs";
import AddDocument from "./AddDocument";
import ListDocuments from "./ListDocuments";

function DocumentDashboard(props) {
  return (
    <div className="container dashboard statistics-card">
      <Row className="title-row">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          {props.location.state.flag === "Upload" ? (
            <div className="pages-header">Upload Documents</div>
          ) : (
            <div className="pages-header">Audits </div>
          )}
          <Breadcrumb location={props.location} />
        </Col>
        {props.location.state.flag === "Upload" ? (
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <AddDocument opportunityData={props.location.state.data} />
          </Col>
        ) : null}
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <ListDocuments flag={props.location.state.flag} />
        </Col>
      </Row>
    </div>
  );
}

export default DocumentDashboard;
