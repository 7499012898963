import React, { useState } from "react";
import { EyeOutlined } from "@ant-design/icons";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Modal, Button } from "antd";

function PreviewUploadedImage(props) {
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };
  const handleModalCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={showModal}>
        <EyeOutlined />
      </Button>
      <Modal
        width={1200}
        open={open}
        //   onOk={handleOk}
        onCancel={handleModalCancel}
        title="Preview"
        style={{ top: 30 }}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        destroyOnClose={true}
      >
        {(props.fileType == "image/png" || props.fileType == "image/jpeg") && (
          <img style={{ width: "100%" }} src={props.file} />
        )}
        {props.fileType == "application/pdf" && (
          <div
            style={{
              border: "1px solid rgba(0, 0, 0, 0.3)",
              height: "600px",
              overflow: "scroll",
            }}
          >
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.7.107/build/pdf.worker.min.js">
              <Viewer fileUrl={props.file} />
            </Worker>
          </div>
        )}
        {props.fileType == "video/mp4" && <p>Nothing to preview</p>}
      </Modal>
    </>
  );
}

export default PreviewUploadedImage;
