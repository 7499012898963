import { createSlice } from "@reduxjs/toolkit";
import "../../App.css";
import {
  createOpportunity,
  getOpportunityList,
  updateOpportunity,
} from "./opportunity-actions";
import { build } from "pdfjs-dist";

const initialOpportunityState = {
  opportunityList: [],
  createLoading: false,
  updateLoading: false,
  createOppError: false,
  updateOppError: false,

  allteams: [],
  opportunityType: [],
  opportunityDocsList: [],
  allStatus: [],
  allRegion: [],
};

const opportunitySlice = createSlice({
  name: "opportunity",
  initialState: initialOpportunityState,
  extraReducers: (builder) => {
    builder.addCase(getOpportunityList.fulfilled, (state, action) => {
      state.opportunityList = action.payload;
    });
    builder.addCase(getOpportunityList.pending, (state, action) => {
      state.opportunityList = [];
    });
    builder.addCase(getOpportunityList.rejected, (state, action) => {
      state.opportunityList = [];
    });
    builder.addCase(createOpportunity.fulfilled, (state, action) => {
      state.opportunityList = [...state.opportunityList, action.payload];
      state.createLoading = false;
    });
    builder.addCase(createOpportunity.pending, (state, action) => {
      state.opportunityList = [...state.opportunityList];
      state.createLoading = true;
    });
    builder.addCase(createOpportunity.rejected, (state, action) => {
      state.opportunityList = [...state.opportunityList];
      state.createOppError = true;
    });

    builder.addCase(updateOpportunity.fulfilled, (state, action) => {
      const data = action.payload;
      state.updateLoading = false;

      const index = state.opportunityList.findIndex(
        (item) => item.id === data.id
      );
      if (index !== -1) {
        state.opportunityList[index] = data;
      }
    });
    builder.addCase(updateOpportunity.pending, (state, action) => {
      state.opportunityList = [...state.opportunityList];
      state.updateLoading = true;
    });
    builder.addCase(updateOpportunity.rejected, (state, action) => {
      state.opportunityList = [...state.opportunityList];
      state.updateOppError = true;
    });
  },
  reducers: {
    // getOpportunityList(state, action) {
    //   state.opportunityList = action.payload;
    // },

    // addSingleOpportunity(state, action) {
    //   state.opportunityList = [...state.opportunityList, action.payload];
    // },
    // updateOpportunity(state, action) {
    //   const data = action.payload;

    //   const index = state.opportunityList.findIndex(
    //     (item) => item.id === data.id
    //   );
    //   if (index !== -1) {
    //     state.opportunityList[index] = data;
    //   }
    // },
    // addTeamList(state, action) {
    //   state.allteams = action.payload;
    // },
    // getAllOpportunityType(state, action) {
    //   state.opportunityType = action.payload;
    // },
    getOpportunityDocumentsList(state, action) {
      state.opportunityDocsList = action.payload;
    },
    getAllStatusTypeTeam(state, action) {
      state.opportunityType = action.payload.opportunityTypes;
      state.allteams = action.payload.teams;
      state.allStatus = action.payload.status;
      state.allRegion = action.payload.regions;
    },
  },
});

export const opportunityActions = opportunitySlice.actions;

export default opportunitySlice.reducer;
