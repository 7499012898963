import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tag,
  Tooltip,
  message,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateOpportunity,
  // getAllTeamList,
  // getAllOpportunityType,
  getAllOpportunityStatusTypeTeam,
} from "./opportunity-actions";
import { Field } from "rc-field-form";
import { Option } from "antd/lib/mentions";
import { useMsal } from "@azure/msal-react";

export default function UpdateOpportunity(props) {
  const previousEmails = props?.data?.assigned_to;
  const { instance } = useMsal();
  const selectedTeamName = props?.data?.team?.team_name;
  const selectedStatusName = props?.data?.status;
  const selectedTeamId = props?.data?.team?.id;
  const selectedOpportunityTypeId = props?.data?.opportunity_type?.id;
  const selectedOpportunityType =
    props?.data?.opportunity_type?.opportunity_type_name;
  const selectedRegion = props?.data?.region;

  const dispatch = useDispatch();
  const [teamId, setTeamId] = useState(selectedTeamId);
  const [selectPriority, setSelectPriority] = useState(
    props?.data?.priority_level
  );
  const [selStatusName, setSelStatusName] = useState(selectedStatusName);
  const [selRegionName, setSelRegionName] = useState(selectedRegion);
  const [opportunityTypeId, setOpportunityTypeId] = useState(
    selectedOpportunityTypeId
  );
  const [selTeamName, setSelTeamName] = useState(selectedTeamName);
  const [selectOppTypeName, setSelectOppoTypeName] = useState(
    selectedOpportunityType
  );
  const [fields, setFields] = useState({
    title: "",
    description: "",
    source_requestor: "",
    assigned_to: "",
    status: "",
    comments: "",
    client_name: "",
    technology: "",
    emailError: null,
  });

  const [open, setOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [chips, setChips] = useState(previousEmails);

  const data = useSelector((state) => state.opportunity);

  const allTeam = data.allteams;
  const allOpportunityType = data.opportunityType;
  const allStatus = data.allStatus;
  const allRegion = data.allRegion;

  const showModal = () => {
    setOpen(true);
  };
  const onSubmit = () => {
    const allEmails = chips;

    const latestAllEmails = allEmails.join().toString();
    let finalData = {
      title: fields.title ? fields.title : props?.data?.title,
      technology: fields.technology
        ? fields.technology
        : props?.data?.technology,
      description: fields.description
        ? fields.description
        : props?.data?.description,
      source_requestor: instance.getActiveAccount().username,
      assigned_to: latestAllEmails ? latestAllEmails : chips,
      status: selectedStatus ? selectedStatus : props?.data?.status,
      comments: fields.comments ? fields.comments : props?.data?.comments,
      client_name: fields.client_name
        ? fields.client_name
        : props?.data?.client_name,
      opportunity_id: props?.data?.id,
      team_id: teamId,
      opportunity_type_id: opportunityTypeId,
      region: selRegionName,
      priority_level: selectPriority,
      updated_by_email: instance.getActiveAccount().username,
      updated_by_name: instance.getActiveAccount().name,
      created_by_email: instance.getActiveAccount().username,
      created_by_name: instance.getActiveAccount().name,
    };
    dispatch(updateOpportunity(finalData))
      .then((res) => {
        setOpen(false);
        message.success("opportunity updated successfully");
      })
      .catch((err) => {
        message.error(err.message);
        console.log("error", err);
      });
  };
  const handleKeyDown = (e) => {
    if (["Enter", "Tab", ",", " "].includes(e.key)) {
      e.preventDefault();
      const value = fields.assigned_to.trim();
      if (value && isValid(value)) {
        const newChips = [...chips, fields.assigned_to];
        setChips(newChips);
        const field = { ...fields };
        field["assigned_to"] = "";
        setFields(field);
      }
    }
  };
  const handleChipClose = (chip) => {
    const updatedChips = chips.filter((c) => c !== chip);
    setChips(updatedChips);
  };

  const isValid = (email) => {
    let emailError = null;

    if (isInList(email)) {
      emailError = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      emailError = `${email} is not a valid email address.`;
    }

    if (emailError) {
      setFields({ emailError });

      return false;
    }

    return true;
  };
  const handleEmailChange = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
      emailError: null,
    });
  };

  const isInList = (email) => {
    return chips.includes(email);
  };
  const isEmail = (email) => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields({ ...fields, [name]: value });
  };
  const handleModalCancel = () => {
    setOpen(false);
  };
  return (
    <>
      <Col xs={24} sm={24} md={6} lg={6} xl={6}>
        <Tooltip title="Update Opportunity">
          <Button
            size="small"
            shape="circle"
            icon={<EditOutlined />}
            onClick={showModal}
            style={{
              color: "white",
              background: "#E97451",
            }}
          />
        </Tooltip>
      </Col>

      <Modal
        open={open}
        // onOk={handleOk}

        onCancel={handleModalCancel}
        title="Update Opportunity"
        style={{ top: 20 }}
        width={650}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        destroyOnClose={true}
      >
        <Form
          name="update Opportunity"
          className="update Opportunity"
          onFinish={onSubmit}
          initialValues={{
            description: props.data.description,
            comments: props.data.comments,
            remember: true,
          }}
        >
          <Row gutter={[8, 8]}>
            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
              <Tooltip title="Update Opportunity Title">
                <Form.Item
                  initialValue={props.data.title}
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter title!",
                    },
                  ]}
                >
                  <Input
                    autoFocus
                    placeholder="Enter Title"
                    name="title"
                    value={fields.title}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Tooltip>
            </Col>
            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
              <Tooltip title="Update Client Name">
                <Form.Item
                  initialValue={props.data.client_name}
                  name="client_name"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Client Name!",
                    },
                  ]}
                >
                  <Input
                    autoFocus
                    placeholder="Enter Client Name"
                    name="client_name"
                    value={fields.client_name}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Tooltip>
            </Col>
            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
              <Tooltip title="Source Requester">
                <Form.Item
                  initialValue={props.data.source_requestor}
                  name="source_requestor"
                  rules={[
                    {
                      required: true,
                      message: "Enter Source Requester!",
                    },
                  ]}
                >
                  <Input
                    autoFocus
                    placeholder="Enter Source Requester"
                    name="source_requestor"
                    value={fields.source_requestor}
                    readOnly={true}
                    // onChange={handleChange}
                    disabled
                  />
                </Form.Item>
              </Tooltip>
            </Col>
          </Row>

          <Row gutter={[8, 8]}>
            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
              <Form.Item name="Priority" noStyle>
                <Tooltip title="Select Priority">
                  <Select
                    defaultValue={props.data.priority_level}
                    placeholder=" Select Priority"
                    style={{
                      width: "100%",
                    }}
                    onChange={(select) => {
                      setSelectPriority(select);
                    }}
                  >
                    {["HIGH", "MEDIUM", "LOW"]?.map((item) => {
                      return <Option value={item}>{item}</Option>;
                    })}
                  </Select>
                </Tooltip>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
              <Tooltip title="Update Status">
                <Form.Item name="status" noStyle>
                  <Tooltip title="Select Status">
                    <Select
                      defaultValue={selStatusName}
                      style={{
                        width: "100%",
                      }}
                      onChange={(select) => {
                        setSelectedStatus(select);
                      }}
                    >
                      {allStatus?.map((item) => {
                        return (
                          <Option value={item.opportunity_status}>
                            {item.opportunity_status}
                          </Option>
                        );
                      })}
                    </Select>
                  </Tooltip>
                </Form.Item>
              </Tooltip>
            </Col>
            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
              <Tooltip title="Enter Technology">
                <Form.Item
                  initialValue={props?.data?.technology}
                  name="technology"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Technology!",
                    },
                  ]}
                >
                  <Input
                    autoFocus
                    placeholder="Enter Technology"
                    name="technology"
                    value={fields.technology}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Tooltip>
            </Col>
          </Row>

          <Row gutter={[8, 8]}>
            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
              <Form.Item name="teamname" noStyle>
                <Tooltip title="Select Team Name">
                  <Select
                    defaultValue={selTeamName}
                    // placeholder="Select Team"
                    style={{
                      width: "100%",
                    }}
                    onChange={(select) => {
                      setTeamId(select);
                    }}
                  >
                    {allTeam?.map((item) => {
                      return <Option value={item.id}>{item.team_name}</Option>;
                    })}
                  </Select>
                </Tooltip>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
              <Form.Item name="region" noStyle>
                <Tooltip title="Select Region">
                  <Select
                    defaultValue={selectedRegion}
                    // placeholder=" Select Region"
                    style={{
                      width: "100%",
                    }}
                    onChange={(select) => {
                      setSelRegionName(select);
                    }}
                  >
                    {allRegion?.map((item) => {
                      return <Option value={item.region}>{item.region}</Option>;
                    })}
                  </Select>
                </Tooltip>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
              <Form.Item name="opportunitytype" noStyle>
                <Tooltip title="Select Opportunity">
                  <Select
                    defaultValue={
                      props?.data?.opportunity_type?.opportunity_type_name
                    }
                    // placeholder=" Select Opportunity Type"
                    style={{
                      width: "100%",
                    }}
                    onChange={(select) => {
                      setOpportunityTypeId(select);
                    }}
                  >
                    {allOpportunityType?.map((item) => {
                      return (
                        <Option value={item.id}>
                          {item.opportunity_type_name}
                        </Option>
                      );
                    })}
                  </Select>
                </Tooltip>
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ marginTop: "26px" }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div>
                {chips.map((chip) => (
                  <Tag
                    key={chip}
                    closable
                    onClose={() => {
                      handleChipClose(chip);
                    }}
                    style={{
                      marginRight: "8px",
                      marginBottom: "8px",
                      borderRadius: "10px",
                      height: "30px",
                    }}
                  >
                    {chip}
                  </Tag>
                ))}
              </div>
              <Tooltip title="Assignee">
                <Form.Item
                  name="assigned_to"
                  rules={[
                    {
                      required: chips.length ? false : true,
                      message: "Enter a Email",
                    },
                  ]}
                >
                  <Input
                    style={{
                      border: fields.emailError ? "1px solid red" : null,
                    }}
                    autoFocus
                    placeholder="Enter Assignee Email"
                    name="assigned_to"
                    onKeyDown={handleKeyDown}
                    value={fields.assigned_to}
                    onChange={handleEmailChange}
                  />
                  {fields.emailError && (
                    <p className="error" style={{ color: "red" }}>
                      {fields.emailError}
                    </p>
                  )}
                </Form.Item>
              </Tooltip>
            </Col>
          </Row>

          <Row gutter={[8, 8]}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Field name="description">
                <TextArea
                  autoFocus
                  placeholder="Type Description..."
                  name="description"
                  value={fields.description}
                  onChange={handleChange}
                  rows={4}
                />
              </Field>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Field name="comments">
                <TextArea
                  autoFocus
                  placeholder="Type Comments..."
                  name="comments"
                  value={fields.comments}
                  onChange={handleChange}
                  rows={4}
                />
              </Field>
            </Col>
          </Row>

          <Form.Item style={{ float: "right", margin: "10px 0" }}>
            <Button
              className="close-modal"
              style={{ margin: "0 10px 0 0" }}
              onClick={handleModalCancel}
            >
              Close
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="ok-modal"
              loading={data.updateLoading}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
