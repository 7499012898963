import React, { useState, useEffect } from "react";
import { Pie } from "@ant-design/plots";
import { Tooltip, Col, DatePicker, Button, Row, Spin, Empty } from "antd";
import moment from "moment";
import axios from "axios";
import { CSVLink } from "react-csv";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";

//   { id: 1, name: "January" },
//   { id: 2, name: "February" },
//   { id: 3, name: "March" },
//   { id: 4, name: "April" },
//   { id: 5, name: "May" },
//   { id: 6, name: "June" },
//   { id: 7, name: "July" },
//   { id: 8, name: "August" },
//   { id: 9, name: "September" },
//   { id: 10, name: "October" },
//   { id: 11, name: "November" },
//   { id: 12, name: "December" },
// ];
const monthFormat = "YYYY-MM";
const defaultMonth = moment(new Date()).month() + 1;
const defaultYear = moment(new Date()).year();
var initalMonth = defaultYear + "-" + defaultMonth;

export default function OpportunityTypePi() {
  const [loading, setLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [pieData, setPieData] = useState([]);

  const params = {
    month: selectedMonth,
    year: selectedYear,
    graph_type: "opp_type",
  };

  useEffect(() => {
    getAlllOpportunityTypeData();
  }, [selectedMonth, selectedYear]);

  const getAlllOpportunityTypeData = () => {
    const url =
      "https://bgzz8fj81j.execute-api.ap-south-1.amazonaws.com/dev/dashboard/opp-piechart-data";
    axios
      .get(url, { params: params })
      .then((res) => {
        setPieData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setPieData(error.response.data);
          setLoading(false);
        }
      });
  };

  const handleMonthChange = async (data, dateString) => {
    setLoading(true);
    setPieData([]);
    setSelectedYear(dateString.slice(0, 4));
    setSelectedMonth(dateString.slice(5, 8));
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const config = {
    appendPadding: 10,
    data: pieData,
    angleField: "opp_count",
    colorField: "opportunity_type_name",
    radius: 0.8,
    label: {
      type: "spider",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };

  const headers = [
    { label: "Opportunity Type", key: "opportunity_type_name" },
    { label: "Total Opportunities", key: "opp_count" },
    { label: "Modified Date", key: "updatedAt" },
    { label: "Month", key: "month" },
    { label: "Year", key: "year" },
  ];

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 34,
      }}
      spin
    />
  );
  return (
    <div>
      <div className="d-flex justify-content-center mb-2">
        <h5 className="text-secondary " style={{ fontSize: "1rem" }}>
          Opportunities By Type
        </h5>

        <h6
          className="text-secondary mt-1 ms-3"
          style={{ fontSize: "0.75rem" }}
        >
          Monthly
        </h6>
      </div>
      <Row gutter={[8, 8]} className="my-3">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <DatePicker
            allowClear={false}
            disabledDate={disabledDate}
            onChange={handleMonthChange}
            defaultValue={moment(initalMonth, monthFormat)}
            format={monthFormat}
            picker="month"
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <CSVLink
            className="float-end btn-dwn"
            data={pieData}
            headers={headers}
            filename={"Opportunities_Type_Count_Month_Wise"}
          >
            <Tooltip title="Export to csv">
              <div className="download-btn">
                <Button size="large" type="text">
                  <DownloadOutlined className="text-black " />
                </Button>
              </div>
            </Tooltip>
          </CSVLink>
        </Col>
      </Row>

      {loading && (
        <div className="mt-5 d-grid">
          <Spin indicator={antIcon} />
          <p className="d-flex justify-content-center text-secondary">
            Loading...
          </p>
        </div>
      )}

      {!pieData.length && !loading ? (
        <Empty
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 100,
          }}
        />
      ) : (
        <Pie {...config} />
      )}
    </div>
  );
}
