import React from "react";
import { Col, Row } from "antd";
import Breadcrumb from "../BreadCrumbs";
import ListOpportunities from "./ListOpportunities";
import AddOpportunity from "./AddOpportunity";

function OpportunityDashboard(props) {
  return (
    <div className="container dashboard statistics-card">
      <Row className="title-row">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <div className="pages-header">Opportunities</div>
          <Breadcrumb location={props.location} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <AddOpportunity />
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <ListOpportunities />
        </Col>
      </Row>
    </div>
  );
}

export default OpportunityDashboard;
