import React, { useEffect, useState } from "react";
import { Column } from "@ant-design/plots";
import axios from "axios";
import { Form, Tooltip, Col, Row, Button, Spin, Empty } from "antd";
import { CSVLink } from "react-csv";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";

export default function OpportunityBar() {
  const [barData, setBarData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllMonthData();
  }, []);
  const getAllMonthData = () => {
    const url =
      "https://bgzz8fj81j.execute-api.ap-south-1.amazonaws.com/dev/dashboard/opp-bar-graph-data";
    axios
      .get(url)
      .then((res) => {
        setBarData(res.data.body);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setBarData(error.response.data);
          setLoading(false);
        }
      });
  };
  const config = {
    data: barData,
    xField: "month",
    yField: "value",
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    minColumnWidth: 20,
    maxColumnWidth: 40,
  };

  const headers = [
    { label: "Month", key: "month" },
    { label: "Value", key: "value" },
  ];
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 34,
      }}
      spin
    />
  );
  return (
    <div>
      <div className="d-flex justify-content-center mb-2">
        <h5 className="text-secondary " style={{ fontSize: "1rem" }}>
          Number of Opportunities
        </h5>
        <h6
          className="text-secondary mt-1 ms-3"
          style={{ fontSize: "0.75rem" }}
        >
          Monthly
        </h6>
      </div>
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <CSVLink
            className="float-end btn-dwn"
            data={barData}
            headers={headers}
            filename={"Total_Opportunities_Monthly"}
          >
            <Tooltip title="Export to csv">
              <div className="download-btn">
                <Button size="large" type="text">
                  <DownloadOutlined className="text-black " />
                </Button>
              </div>
            </Tooltip>
          </CSVLink>
        </Col>
      </Row>

      {loading && (
        <div className="mt-5 d-grid">
          <Spin indicator={antIcon} />
          <p className="d-flex justify-content-center text-secondary">
            Loading...
          </p>
        </div>
      )}
      {!barData.length && !loading ? (
        <Empty
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 100,
          }}
        />
      ) : (
        <Column {...config} />
      )}
    </div>
  );
}
